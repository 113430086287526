import React, { useState } from 'react';
import Translations from "./books";

function Tabs() {
  const [activeTab, setActiveTab] = useState('Ukrainian')
  const tabs = [
    { id: 'Ukrainian', label: 'Into Ukrainian', lang: 'uk' },
    { id: 'English', label: 'Into English', lang: 'en'  }
  ];
  const handleTabChange = (tabId) => {
    setActiveTab(tabId)
  };
  return (
    <>
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex gap-6" aria-label="Tabs">
          {tabs.map((tab) => (
            <a
              key={tab.id}
              href="#"
              className={`shrink-0 border-b-2 px-1 pb-4 text-lg font-medium ${
                activeTab === tab.id
                  ? 'border-sky-500 text-sky-600'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
              }`}
              onClick={(e) => {
                e.preventDefault();
                handleTabChange(tab.id);
              }}
              aria-current={activeTab === tab.id ? 'page' : undefined}
            >
              {tab.label}
            </a>
          ))}
        </nav>
      </div>
      <Translations lang={tabs.find((tab) => tab.id === activeTab)?.lang} />
    </>
  );
}

export default Tabs;
