import * as React from 'react'
import { title, translations } from '../../content/translation_misc.json'
import * as styles from './index.module.css'

const Translation = ({ title, author, publication, src }) =>
  <li className={styles.listItem}>
    {author}, <a className={styles.listItemLink}
    href={src}
    target="_blank"
    rel="noreferrer">{title}</a>, {publication}
  </li>

const TranslationMisc = () =>
  <>
    <h3>{title}</h3>
    <ul>{translations.map((item, idx) => <Translation key={idx} {...item} />)}</ul>
  </>

export default TranslationMisc
