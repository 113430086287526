import * as React from 'react'
import { title, items } from '../../content/roles.json'

const Roles = () =>
  <>
    <h3>{title}</h3>
    <ul>{items.map(({position, organizer, year}, idx) => <li key={idx}>{position}, {organizer}{year ? `, ${year}` : ''}</li>)}</ul>
  </>

export default Roles
