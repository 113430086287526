import * as React from 'react'
import { title, items } from '../../content/awards.json'

const Awards = () =>
  <>
    <h3>{title}</h3>
    <ul>{items.map(({organizer, scholarship}, idx) => <li key={idx}>{organizer}{scholarship ? `, ${scholarship}` : ''}</li>)}</ul>
  </>

export default Awards
